import { media } from "~theme/breakpoints"

export const container = {
  backgroundColor: "background",
  variant: "styles.Container",
  [media.tablet]: {
    py: 4,
    px: 4,
    pt: 5,
  },
}
