/** @jsx jsx */
import { jsx, Container } from "theme-ui"
import Scroll from "../../../components/scroll"

import * as styles from "./styles"

export const MainContainer = ({ children, ...rest }) => {
  return (
    <Container sx={styles.container} {...rest}>
      <div
        className="container"
        style={{
          maxWidth: "850px",
          marginTop: "125px",
        }}
      >
        {children}
      </div>
      <Scroll showBelow={250} />
    </Container>
  )
}
