import React, { useState, useEffect } from "react"
import { ArrowUpCircleFill } from "react-bootstrap-icons"
import { useCurrentDoc } from "docz"

const Scroll = ({ showBelow }) => {
  const currentDoc = useCurrentDoc()
  const [show, setShow] = useState(showBelow ? false : true)

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })

  return (
    <>
      {currentDoc.route !== "/" && show && (
        <ArrowUpCircleFill
          style={{
            zIndex: 2,
            position: "fixed",
            bottom: "5vh",
            right: "5%",
          }}
          color="black"
          size={30}
          onClick={handleClick}
        />
      )}
    </>
  )
}

export default Scroll
